@import "../../styles/variable.less";

.tab-nav {
  position: relative;
  flex-shrink: 0;
  display: flex;
  background-color: #fff;
}

.tab-nav-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    .tab-nav-text {
      position: relative;
      color: @primary;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        border-top: 2px solid @primary;
      }
    }
  }
}

.tab-nav-text {
  height: 50px;
  display: flex;
  align-items: center;
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;