.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.scroll-touch {
  -webkit-overflow-scrolling: touch;
}
.border-bottom {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWP4TwFgOHfuEtkIAChpbF2HSOKlAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-top {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWM4d+4S2YjhPwUAAH7AbF3Y6sgeAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-left {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWM4d+7S////BwUFAJJVbF33ZA94AAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
.border-right {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWP4////uXOXBgUFALPZbF01SeOdAAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
.tab-nav {
  position: relative;
  flex-shrink: 0;
  display: flex;
  background-color: #fff;
}
.tab-nav-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-nav-item.active .tab-nav-text {
  position: relative;
  color: #22b8ff;
}
.tab-nav-item.active .tab-nav-text::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-top: 2px solid #22b8ff;
}
.tab-nav-text {
  height: 50px;
  display: flex;
  align-items: center;
}
