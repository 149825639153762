@import "../../styles/variable.less";

// checkbox
.checkbox {
  position: relative;
  display: flex;
  width: 75px;
  height: 40px;
  padding-right: 15px;
  justify-content: flex-end;
  align-items: center;

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid @primary;
    border-radius: 2px;
  }

  &.checked::before {
    color: #fff;
    background: @primary url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAlUlEQVR4Ae3S0QnDMAyE4Y7QETJCRskI2ijdwNko3aTdQNWby1FUMBxHsH7Qm+ETyLeqqqopcvcl5owxBb7GvLxnEhzaNXjPpsTfMRsb3xJ8ZePm0WVxvOGuwu3rhk2BY20Af8YsI3c8/HdnzB3ePhK8vyUskbwBnLREo+L/l2DjcGcBnv906KDBuIQMxyUEOCxBwKsP5PnQcx1xv6gAAAAASUVORK5CYII=") no-repeat center / 12px 12px;
  }
}

// tag
.tag {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 4px 0 12px;
  font-size: 12px;
  height: 20px;
  padding-left: 12px;
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFcAAAA8CAQAAAC58u5+AAAB5klEQVR42tXaPUsDQRAG4GiIIoiFnyAGBQuxELQRFcTK2sJKLQTNPxBsbaKVVoI2glZipY1go4KlRQRRQQhYiCBkZu/Mh0UkcEJETbJ3yV2afYfrss3Dcdl9Z3ZCTijYk27nXbrnAjkmnoBYNcVvZqB1cO0xTpvEBuLSEKXMYgNwVZRfTWN9c7Nd/Gwe65Or2iiBgPXHbaEbDKwfboTOUbC1uY10jIOtyeU9JGwNLm9iYatyaQ0NW4WrVvCwnlw1bypz1cFVs5xHxLpy1QTlMLEuXGuELVSsxv0YpHdcbAWXeukFGUsVVdgjNraU28q36Nh/bjNf4mN/uWE+k4D94Tbwkb7AD7Sk+pymXA/P8TUQl9c1akFtOJGyoyNGWQiu3c9fGjfuks8WIbhqW/sxUf5m/8AnAFx9r+Vlj7A+DsClT4077BErw+ZDpQtXRT3rixzgx2BNu2MzHZh/tR2P0B5D2MgGKjcyztujLl2ybvP9R69j4inTqTWeLnAO4UNtIWUtlLShZyiJFXFOXRaTdMBbvE93iAHySk6AFBfPxRU/0kpLcYW7uLaIuKaTuJaeuIZpEbwqiius2V/ManFRXGEXVeKuAaVdsoq7whY3ICBt/ELccIu40SFxg1nFT2JS0Nib+aHCb8v/HUSsN981AAAAAElFTkSuQmCC") no-repeat 0;
  background-size: auto 20px;
  border-radius: 0 2px 2px 0;
  color: @text-grey;
  overflow: hidden;
}

.tag>span {
  position: relative;
  z-index: 2;
  line-height: 1;
}

.tag::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 12px;
  z-index: 1;
  background-color: #ebebeb;
}

.tag.tag-warn {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFcAAAA8CAYAAAAT+yb1AAACXUlEQVR4Ae3cAWRWURQH8CqVSKgq0hQhITrnvm/fvjEFIRAyVIgaABABFFagQBRE4Zz7Po8WZEFFgGiRioiQiCFSYRn/3lSQzbuP+zD9xwHe5fq59t177rlnTdd/qAbb4Hqzjld1LMIE/0t0C1v2xmHyKfOkiYuyEJh+zTxh4qIqDsJlPvNkiYuyNwLXj5knSlxUEzth+o6wmXEx298K0zmiZsZFNdgM12cEzYyLF2EDTB8SMzMucGUdTCIhu8B1uUXEDnDhepWAHeDCwkXidYCLUs4TrgNcRDnVQWaLuIjFcbgsEC0zLoY6BpPvBMuMCwuH4fKFWJlxMQwHYPqZUJlxUcoemHwgUmbc3/de8oZAmXFRHdsCl+fEyYyL2RObYPqYMJlxUU2uh+sDomTGBbAWJvfSBulruJ7F/f5eVJMbEfu74cVJuD4l6HK4ppcaP3ZdrOPyUnJ85VNcmILLN8L+wYXLPpj+TFix04kZszOE/Ysb5XoC7NzSim2R6x3ChLhJ+9kYzrVLpBejMAFxTX80fjjUQ61w651HamqSuGVvpG1SPTWLxn8LpU60gp0Z3Z46Af6gebjRCjeGKZiAuOXY/satmMsCvHckbdWO72KFY9tDhMlbxLCjsazJ9BFh/z3+ut5tHOAyj1icXhY29o7C9D1RV0rcmM6kDawRXe4gyjV4uA3Xl8RMSTm6PiEMk+W85skXvKBk8GqdRSEsZ2KwEI8lpCx+9uIC4Vi2v4ofnJhOE5BPpfjIj7h8nsqH1WwJQFw2s2AbFjYQWjXB1lds2hZlwKZtbDfYSfwCcMo7CXXvUeAAAAAASUVORK5CYII=");
  color: #fff;
}

.tag.tag-warn::after {
  background-color: @warn;
}

// 没找到 tag下有icon 先注释 2020-02-21 15：13
// .tag .svg-icon {
//   position: absolute;
//   top: 0;
//   left: -20px;
//   width: 20px!important;
//   height: 20px!important;
//   color: #ebebeb;
// }

.tag-warn .svg-icon {
  color: @warn;
}

// btn btn-group
.btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-group .btn {
  position: relative;
  flex: 1;
  color: @primary;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

.btn-group .btn+.btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #f3f3f3;
}

// Badge
.badge {
  display: inline-block;
  margin-right: 10px;
  padding: 2px 4px;
  font-size: 12px;
  background-color: #e9e9e9;
  color: @text;
  border-radius: 2px;
}

.badge.badge-primary {
  background-color: @primary;
  color: #fff;
}

.badge.badge-primary.badge-light {
  color: @primary;
  background-color: rgba(@primary, 0.2);
}

.badge.badge-warn {
  background-color: @warn;
  color: #fff;
}

.badge.badge-warn.badge-light {
  color: @warn;
  background-color: rgba(@warn, 0.2);
}

.badge.badge-strong {
  background-color: @strong;
  color: #fff;
}

.badge.badge-strong.badge-light {
  color: @strong;
  background-color: rgba(@strong, 0.2);
}

.badge.badge-danger {
  background-color: @danger;
  color: #fff;
}

.badge.badge-danger.badge-light {
  color: @danger;
  background-color: rgba(@danger, 0.2);
}

.badge.badge-light {
  color: #818794;
}

.badge.badge-reverse {
  background-color: #dde0e4;
  color: #fff;
}

// Pagination
.pagination {
  display: flex;
  justify-content: space-between;
  height: 45px;
}

.pagination span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-nav {
  color: @primary;
}

.page-nav.disabled {
  color: #a0a9b5;
}

// Modal
.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow: auto;
  background-color: #f3f3f5;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
}

.modal.opened {
  display: flex;
  flex-direction: column;
}

.modal-header {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  background-color: #fff;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    .border-bottom;
  }
}

.modal-header.hide {
  display: none;
}

.modal-header h3 {
  position: absolute;
  left: 75px;
  right: 75px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  font-weight: 500;
}

.modal-header span {
  display: flex;
  align-items: center;
  width: 75px;
  height: 35px;
  color: @primary;
  font-size: 14px;
  white-space: nowrap;
}

.modal-header .modal-cancel {
  padding-left: 15px;
  justify-content: flex-start;
}

.modal-header .modal-sure {
  text-align: right;
  padding-right: 15px;
  justify-content: flex-end;
}

.modal>.apply-group {
  margin-top: 10px;
}

.modal>.btn-group {
  margin-top: 25px;
  height: 45px;
  justify-content: center;
}

.modal>.btn-group .btn {
  max-width: 130px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  color: @text;
  font-weight: 400;
  border-radius: 5px;
}

.modal>.btn-group .btn-primary {
  margin-left: 15px;
  border-color: @primary;
  background-color: @primary;
  color: #fff;
}

.modal>.btn-group .btn+.btn::before {
  display: none;
}

.modal-body {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-footer {
  flex-shrink: 0;
  height: 50px;
  background-color: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.modal-block-btn {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: @primary;
  background-color: #fff;
  border: none;
  padding: 0;

  .svg-icon {
    margin-right: 10px;
  }

  &.mbb-primary {
    color: #fff;
    background-color: @primary;
  }
}

// swipeout
.rc-swipeout {
  overflow: hidden;
  position: relative;
}

.rc-swipeout-content {
  position: relative;
  background-color: #fff;
}

.rc-swipeout-cover {
  position: absolute;
  z-index: 2;
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  display: none;
}

.rc-swipeout .rc-swipeout-content,
.rc-swipeout .rc-swipeout-actions {
  transition: all 250ms;
}

.rc-swipeout-swiping .rc-swipeout-content {
  transition: none;
}

.rc-swipeout-actions {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.rc-swipeout-actions-left {
  left: 0;
}

.rc-swipeout-actions-right {
  right: 0;
}

.rc-swipeout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.rc-swipeout-btn-text {
  padding: 0 6px;
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;