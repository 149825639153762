@charset "utf-8";
.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.scroll-touch {
  -webkit-overflow-scrolling: touch;
}
.border-bottom {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWP4TwFgOHfuEtkIAChpbF2HSOKlAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-top {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWM4d+4S2YjhPwUAAH7AbF3Y6sgeAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-left {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWM4d+7S////BwUFAJJVbF33ZA94AAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
.border-right {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWP4////uXOXBgUFALPZbF01SeOdAAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  -webkit-font-smoothing: antialiased;
}
@media screen and (max-width: 360px) {
  body {
    font-size: 14px;
  }
}
a:focus,
button:focus,
[role="button"]:focus {
  outline: none;
}
::placeholder {
  color: #999;
}
textarea {
  font-family: inherit;
  appearance: none;
  resize: none;
}
input,
button {
  appearance: none;
}
pre {
  margin: 0;
  font-family: inherit;
  white-space: pre-wrap;
}
.promise-dialog__toast .promise-dialog__title,
.promise-dialog__loading .promise-dialog__title {
  color: #fff;
}
.promise-dialog__layer .promise-dialog__title {
  color: #333;
}
.exception-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}
.ep-body {
  text-align: center;
}
.ep-body img {
  width: 200px;
  height: auto;
}
.ep-body h3 {
  margin: 10px 0;
  font-weight: 400;
  color: #999;
}
.ep-body h3 p {
  margin: 0 0 5px;
  font-size: 0.8em;
}
.ep-body .btn {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 6em;
  max-width: 8em;
  padding: 0.3em 0;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  background-color: #22b8ff;
  border-radius: 2px;
}
.user-card {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  white-space: nowrap;
}
.user-card + .user-card {
  margin-top: 8px;
}
.user-card .avatar {
  margin-right: 5px;
}
.user-card .user-code {
  margin-left: 8px;
  color: #a8b1bd;
}
/* 列表带过滤头的页面模板 */
.list-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
}
.list-page .list-header {
  flex-shrink: 0;
  height: 50px;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
}
.list-page .list-body {
  flex: 1;
  padding: 10px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.list-page .list-footer {
  flex-shrink: 0;
  min-height: 45px;
  border-top: 1px solid #ededed;
  background-color: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.list-page .list-footer.btn-group {
  min-height: 65px;
}
.list-page .list-empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.list-empty a {
  color: #22b8ff;
  text-decoration: none;
}
/* 列表卡片 */
.card-item {
  display: flex;
  justify-content: space-between;
  margin: 0 8px 10px;
  padding: 10px 15px;
  background-color: #fff;
  color: #333;
  text-decoration: none;
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.03);
  border: 1px solid #ededed;
}
.no-avatar .card-item {
  padding-left: 0;
}
.card-item .avatar {
  flex-shrink: 0;
}
.card-item .card-content {
  flex: 1;
  padding-left: 10px;
  color: #666;
}
.card-content .title {
  margin: 0 0 5px;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.card-content .time {
  display: flex;
  align-items: center;
  margin: 2px 0 5px;
  font-size: 12px;
  color: #999;
}
.card-content p,
.card-content .line {
  margin: 0;
  color: #666;
}
.card-content .line {
  margin-bottom: 5px;
}
.card-content .line:last-child {
  margin-bottom: 0;
}
/* 时间进度tag */
.process-status {
  display: flex;
  align-items: center;
  height: 18px;
  padding: 10px;
  font-size: 12px;
  background-color: #f2f2f2;
  border-radius: 9px;
  color: #22b8ff;
  white-space: nowrap;
}
.process-status.overdue {
  color: #ff3b30;
}
/* 重要程度 */
.emergency-line {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.emergency-fire {
  display: flex;
  align-items: center;
}
.emergency-fire + .process-status {
  margin-left: 10px;
}
.emergency-fire .svg-icon {
  color: #d3d3d3;
}
.level-0.active {
  color: #f8be1c;
}
.level-1.active {
  color: #f69623;
}
.level-2.active {
  color: #f56e23;
}
.level-3.active {
  color: #f53d23;
}
.apply-level {
  display: inline-block;
  padding: 0 5px;
  color: #fff;
  border-radius: 0.2em;
  font-size: 12px;
  font-weight: 400;
}
.apply-level.active {
  background-color: #f8be1c;
}
.apply-level.level-0::before {
  content: "";
}
.apply-level.level-1 {
  background-color: #f69623;
}
.apply-level.level-1::before {
  content: "普通";
}
.apply-level.level-2 {
  background-color: #f56e23;
}
.apply-level.level-2::before {
  content: "紧急";
}
.apply-level.level-3 {
  background-color: #f53d23;
}
.apply-level.level-3::before {
  content: "特急";
}
/* filter */
.filter {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.filter-type {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-type.active > span {
  color: #22b8ff;
}
.filter-type.active > span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #22b8ff;
}
.filter-type.selected > span {
  color: #22b8ff;
}
.filter-type.opened .filter-dropdown {
  display: block;
}
.filter-type.opened > span {
  color: #22b8ff;
}
.filter-type.opened > span .svg-icon {
  transform: rotateZ(-90deg);
}
.filter-type > span {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-type > span .svg-icon {
  margin-left: 5px;
}
.filter-search {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.filter-dropdown-content {
  max-height: 80vh;
  overflow: auto;
}
.filter-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
}
.filter-dropdown::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 50px);
  background-color: rgba(0, 0, 0, 0.2);
}
.filter-dropdown .filter-option {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 15px;
  border-top: 1px solid #eee;
  background-color: #fff;
}
.filter-dropdown .filter-option .svg-icon {
  display: none;
}
.filter-dropdown .filter-option.active {
  color: #22b8ff;
}
.filter-dropdown .filter-option.active .svg-icon {
  display: block;
}
.filter-type .actived .svg-icon {
  color: #22b8ff;
}
.filter-select {
  display: flex;
}
.filter-select .filter-droplabel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-droplabel > .svg-icon,
.filter-droplabel > span .svg-icon {
  margin-left: 5px;
  transform: rotateZ(90deg);
  transition: transform ease 0.3s;
}
.filter-droplabel.active {
  color: #22b8ff;
}
.datepicker.ios .datepicker-navbar {
  border-bottom: 1px solid #ddd !important;
}
.datepicker.ios .datepicker-navbar-btn {
  color: #22b8ff !important;
}
.datepicker .datepicker-scroll > li {
  font-size: 1.1em !important;
}
.picture-upload {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 15px 0;
  background-color: #fff;
}
.picture-box {
  position: relative;
  flex-shrink: 0;
  width: 105px;
  height: 105px;
  margin: 0 15px 15px 0;
  padding: 5px;
  border-radius: 2px;
  background: #fff none no-repeat center / contain;
  background-origin: content-box;
  border: 1px solid #ededed;
}
.picture-box:nth-child(3n) {
  margin-right: 0;
}
.picture-box img {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.picture-preview {
  max-width: 100vw;
  display: flex;
  padding: 14px;
  flex-wrap: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.picture-preview .picture-box {
  margin-right: 15px;
  margin-bottom: 0;
}
.picture-preview::after {
  flex-shrink: 0;
  content: "";
  display: block;
  height: 105px;
  width: 1px;
}
.picture-box .pb-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff3b30;
}
.pb-remove::before {
  content: "";
  display: block;
  width: 13px;
  height: 2px;
  background-color: #fff;
}
.upload-images.btn-upload {
  flex-shrink: 0;
  margin-bottom: 15px;
  width: 105px;
  height: 105px;
  border-radius: 2px;
}
.upload-images .upload-capture {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: #dedede;
  border-radius: 50%;
}
.upload-images .upload-progress {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.preview-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #222;
  display: flex;
  flex-direction: column;
  color: #bbb;
}
.preview-modal-header {
  flex-shrink: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-content {
  flex: 1;
  overflow: hidden;
}
.preview-scroll {
  flex: 1 0;
  display: flex;
  align-items: center;
  height: 100%;
  transition: transform ease-in-out 0.2s;
  /* transform: translate3d(0, 0, 0); */
}
.preview-item {
  flex-shrink: 0;
  width: 100vw;
  padding: 15px;
  display: flex;
  align-self: stretch;
  justify-content: center;
}
.preview-item > img {
  flex: 0;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
  background-image: linear-gradient(45deg, #efefef 25%, rgba(239, 239, 239, 0) 25%, rgba(239, 239, 239, 0) 75%, #efefef 75%, #efefef), linear-gradient(45deg, #efefef 25%, rgba(239, 239, 239, 0) 25%, rgba(239, 239, 239, 0) 75%, #efefef 75%, #efefef);
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}
.preview-modal-footer {
  flex-shrink: 0;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}
.preview-modal-footer > span {
  position: relative;
  flex: 1;
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
}
.preview-modal-footer > span:last-child {
  border-left: 1px solid rgba(255, 255, 255, 0.6);
}
.preview-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABYElEQVR42u2XMUsDMRTHe94Va/Eb+hFcHAVBBIeCS3Xp4HBQEDpYulgcCtJBBL+An6Dkf+AiiPsT73LvYttrXy4pXfKfk98vueQ9cq1WSEjIPoITtcAtxZazLpBlp7KhHyAQHunAAt//m6O+RIPVTS4gPEgVBR6knkTDKcHURsH4t8WRcMOUYMKKSIbHXIzPFXGpUOkmRYWnQ8u7ZCpq8T2Nn1nj/ysw2IJvN6yITQrGTxvjC4UaaVC/Bp841jVFqwrGT5zxS4peXohXjI89dSdTgXN9t0be8EuKEh957rEUYciCoXd8voeU15/uYP0VfgcKA/+sXrwrDPyM2tTxrDDwuil4Vhh4rlpT4foAGKxvCpVifae1x69ULXXw6qgo8XVNAV0nhYGvPUgHBX+cLU2hoUKKb6jAtZ5wL7vllSK7lAl+CrzFjrvq3eLpiDN8487uzD6PMQdhHH4dQkL2kl8vmSB0yQfuuwAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
}
.org-selector {
  display: none;
  flex-direction: column;
  /* justify-content: space-between; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  overflow: auto;
}
.org-selector.opened {
  display: flex;
}
.search-form {
  position: relative;
  height: 50px;
  flex-shrink: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
}
.search-form .search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  color: #999;
}
.search-form .search-text {
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: calc(100vw - 30px);
  padding: 0 10px 0 30px;
  font-size: inherit;
}
.search-form .search-text:focus {
  outline: none;
}
.search-form .search-cancel {
  white-space: nowrap;
  padding: 0 10px;
  color: #22b8ff;
}
.search-form .search-btn {
  display: none;
}
.form-tips {
  margin: 0;
  color: #999;
  font-size: 14px;
  margin-bottom: 7px;
}
