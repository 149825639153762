@import "../../styles/variable.less";

// select list
.data-list {
  margin: 0;
  padding: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  list-style: none;
  flex: 1;
  overflow: auto;
  .scroll-touch;

  >li,
  .data-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0 15px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
    background-color: #fff;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      .border-top;
    }

    &.checked::after {
      content: "";
      display: inline-flex;
      width: 1em;
      height: 1em;
      background: url("data:image/svg+xml,%3Csvg class='icon' viewBox='0 0 1027 1024' xmlns='http://www.w3.org/2000/svg' width='48' height='48'%3E%3Cpath d='M272.113 686.592S600.241 245.376 837.49 124.864c0 0 139.136-31.488 174.4 0 0 0 51.776 50.048-37.056 90.816 0 0-400.512 239.168-556.224 535.808 0 0-94.528 170.496-163.136 161.28-68.608-9.28-81.6-14.848-128-85.248C81.137 756.992 31.09 610.56 5.17 586.496c-25.984-24.128 50.048-111.296 137.216-87.168 87.104 24.128 129.728 187.264 129.728 187.264' fill='%2322b8ff'/%3E%3C/svg%3E") no-repeat center / contain;
      color: @primary;
    }
  }
}

// org select
.org-selector {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.org-selector.opened {
  display: flex;
}

.user-search {
  display: flex;
  padding: 5px 0 5px 10px;
  position: relative;
  background-color: #fff;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    .border-bottom;
  }
}

.user-search .form-control {
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 0 10px;
  height: 30px;
}

.user-search-cancel {
  padding: 0 15px;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.user-result,
.org-list,
.cp-folders {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.user-result .user-list {
  padding: 0;
}

.user-result .user-card {
  margin-top: 0;
  padding: 0 0 0 15px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}

// .user-card .avatar {
//   width: 25px;
//   height: 25px;
// }

.user-result .checkbox {
  flex: 1;
}

.user-empty,
.result-empty {
  text-align: center;
  line-height: 50px;
  color: #a0a9b5;
}

.cp-folders {
  background-color: #f3f3f5;
}

.cp-folder .folder-name {
  margin-left: 15px;
  line-height: 35px;
  font-weight: 600;
}

.cp-list {
  background: #fff;
}

.cp-item {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  line-height: 35px;
}

.org-cart {
  position: relative;
  min-height: 35px;
  max-height: 145px;
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding-left: 15px;
}

.cart-item+.cart-item {
  border-top: 1px solid @border-color;
}

.cart-remove {
  width: 50px;
  height: 35px;
  line-height: 35px;
  padding-right: 15px;
  text-align: right;
  color: #a0a9b5;
}

.org-list .org-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 15px;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    .border-bottom;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;