@import "../../styles/variable.less";

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-header {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  background-color: #fff;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    .border-bottom;
  }
}

.page-footer {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  background-color: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    .border-top;
  }
}

.page-body {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.empty-text {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 40px;
  text-align: center;
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;