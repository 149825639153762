@import "../../styles/variable.less";

.apply-group {
  background-color: #fff;
  border-bottom: 10px solid #f5f5f5;

  >h2 {
    margin: 0;
    padding: 12px 15px 10px;
    font-size: 14px;
    color: @primary;
    font-weight: 600;
  }

  >pre {
    padding: 10px 15px;
  }
}

.form-group {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 15px;

  &.hide {
    display: none;

    +.form-group {
      &::before {
        display: none;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 15px;
    .border-top;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }
}

.form-content {
  flex: 1;
  padding-right: 15px;
}

.form-control {
  flex: 1;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 0;
  font-size: inherit;
  color: inherit;
  background-color: #fff;

  &:focus {
    outline: none;
    border-color: @primary;
  }
}

textarea.form-control {
  height: auto;
  min-height: 40px;
  padding: 10px 0;
}

.form-error {
  padding: 0 0 5px 20px;
  min-width: 100%;
  color: @danger;
}

.form-text {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 30px;
}

.placeholder {
  color: @placeholder;
}

.form-label {
  align-self: flex-start;
  flex-shrink: 0;
  display: block;
  width: 100px;
  padding: 10px 5px;
  color: #777;
  .word-break;
}

.required {
  position: relative;

  .form-label::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 16px;
    width: 8px;
    height: 8px;
    background: none no-repeat center / contain;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAB50lEQVR4Ae2XtYIUQRCGG0twd5tq3N2me9lHwBPcMtxD7AVIsAh7ANwdMpwQCbEI6x7BhirkZk6mb93/u/Wqv76RlmI1ZSpXDE4qCc+1BNexQRQcQAl+W0se/H3AoYIDYOFHdQCCH6kcgBqAsmGXEnDVsa1FuQAIJrM2juTrlYQb6LvTPLRmWYPIMDSG+2g+OVMAR/C5+NvrqKc/i4+JBQiSgztrCT8aQPxC+lPO9H79UwUgaIKP+IQAiUEjzWdBWkuw4KdGyQIcNN4fjOvVLg7Amdm/H74/TtAN88lTCWsZS0XBzP5dMfgAJn5vZCTgvZbWGizyJGJ+StuwB7/TjcH5N/IiT5auPAHD0OQMGWX4OOMlYGj2874cPFsLeJpqYYqlHJZLBYy1VAJWaglv44vDW4rB2BaUUxkAhb8Exb8JzcOQhhoNORp6ke8f49BcqwR8yHoYKpsvj5mIfv2ZZOSgPv9mu6ORIg//zqQ92tNkRbukmIloqbG4P3PwiJhreh+Tx0djGwOEchJ8AJ0hgm7g8zNI9u0eD2APGd2g+EtaUBrGmQHMa0KzN6cjrQVawnlHWFtpKaXvMgMIRUs7xl12JGzGIdqK5UJmALMqBaAGAIfDPQK/XXAAascQwqH2jNq0TH1q+g2LLZ1+MDAlrwAAAABJRU5ErkJggg==");
  }
}

.data-view {
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: center;
  position: relative;
  padding: 10px 22px 10px 0;
  .word-break;

  &::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -7px;
    right: 5px;
    background: none no-repeat center / contain;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACCElEQVR4AeybA6wdURiEt3ZjN6od1bZt21aMNk5q27Zt879niw1v2BvX9m7nr3W27XvhzplkLqP5jrGek5OTk5NT9iUiJeAl8LpUKlWZKvytW7eKIfhTBI/U+Pwe7x2ZSr//1/A/QjDG9KAAgMDN4egPDgGhHwuEnTYI8NDEA4iiKDeC7rZAiFgg5ELQLTEQxjBAyKFDoQ0C/ptCAQFhl8fUhGkeg/4CYQYLhAUxEBawQJjhIKDdx0BYrv0Gw5R5SszosI4Cgs4FYmrCls8QHIRcDBCGwqEFwm6dWicegq4UbRBE5CCchwFCD907sEA4nk6n8zE0h44xEM4wQXhng5DJZAowzBNaw28tHeOlIAgKJx6C7/tNEfa1BcJ1NIeiDBAaiMhLS3PwWSDUQuBnlppwg2KeAAhVbRCMMU0oAIjIkz8B0L7CNQHGThAW7QRZh8ErOIcsxDoROq+zwaSHf2cJf1LXA5TrAPiwLotZl8N7tben3BCBNyJ8TtYtsVW6OUq5KSoii5l3hOfQHozAU5mPxiawHo6GIjKS9Xg8hHuz3hL5ICJdKe8J6ZQXK772rOHfGmNasl6Te43wjSkvSn7Z3amb2PC6VkfAfZbwT/BeI+lDXXdLtX/09dkBOgAict8YU95jEMIWhG//EP4uhrpSHpOCIMj7pSYMVCCek5OTk5PTxw16AAB4ZHWiRguUswAAAABJRU5ErkJggg==");
  }

  &.readonly::after {
    display: none;
  }
}

.link-list {
  flex: 1;
  min-height: 30px;
  overflow: hidden;
  padding-right: 15px;
  display: flex;
  flex-direction: column;

  > a {
    display: flex;
    height: 40px;
    align-items: center;
    color: @primary;
    text-decoration: none;
  }
}

// file list
.file-list {
  flex: 1;
  min-height: 30px;
  overflow: hidden;
  padding-right: 15px;
}

.file-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
  padding: 0 8px 0 45px;
  background-color: #f4f7fa;
  color: @text;
  text-decoration: none;
}

.file-item::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 25px;
  height: 25px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAARVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////9SnXPCAAAAF3RSTlMAIK/v/0CQEN/AcJ+wYDB/8KCAv+BPP3IBOugAAAC1SURBVHgB7dUFwsIwDIbhdP06d7v/TX/FISO0OHkP8Gx1+s9EFmdzFCd0uiQFJEAWMUAOIYDiNFCKAUbI5ACKUAB5KIAqFEAVCqAKBVCHArb2BRhBDjCCB4A08QBqt5PxAJikQNHsVYkBpux2gAKtITbTCgBb80BtdRVeB1Cg69k6CZDSQqkAwLCQLuPrAAqMCbElowCw08w26cPy3IACJTwqd4AeHvU7gIFHhnZyGS4sc/TXN0b/O4CFx0rzAAAAAElFTkSuQmCC") no-repeat 50% 50% / contain;
}

.file-item+.file-item {
  margin-top: 8px;
}

.file-item+.file-upload {
  margin-top: 8px;
}

.file-size {
  font-size: 12px;
  color: #a8b1bd;
}

.file-item p {
  margin: 2px;
}

.file-item p.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* direction: rtl; */
  text-align: left;
}

.file-edit-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  background: rgba(0, 0, 0, 0.1) none no-repeat center / 16px;

  &.file-remove {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M885.314 835.089L555.919 505.693l329.395-329.396c12.128-12.128 12.128-31.792 0-43.919-12.127-12.128-31.792-12.128-43.919 0L511.999 461.774 182.603 132.378c-12.128-12.128-31.792-12.128-43.919 0s-12.128 31.792 0 43.919l329.395 329.396-329.395 329.396c-12.128 12.128-12.128 31.793 0 43.919 12.128 12.128 31.792 12.128 43.919 0l329.396-329.396 329.396 329.396c12.128 12.128 31.793 12.128 43.919 0 12.128-12.127 12.128-31.792 0-43.919z' fill='%23ffffff'/%3E%3C/svg%3E");
  }

  &.file-rename {
    right: 44px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M87.227 954.587h786.286q27.428 0 27.428 27.429t-27.428 27.429H87.227q-27.429 0-27.429-27.429t27.429-27.429zM397.298 731.246l24.85 48.914-246.583 125.257a45.714 45.714 0 0 1-66.084-46.263l33.334-274.56 54.455 6.62-31.287 257.536 231.315-117.486z' fill='%23fff'/%3E%3Cpath d='M413.48 702.537l340.865-486.82a45.714 45.714 0 0 0-11.228-63.653L623.291 68.133a45.714 45.714 0 0 0-63.671 11.245L218.756 566.181l194.725 136.356zm-271.122-122.88L514.692 47.91a100.571 100.571 0 0 1 140.069-24.686l119.826 83.895a100.571 100.571 0 0 1 24.686 140.068L426.939 778.935l-284.58-199.278z' fill='%23fff'/%3E%3C/svg%3E");
  }
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;