@text: #333;
@text-grey: #666;
@text-light: #999;
@bg-light: #f5f5f5;
@padding: 15px;
@placeholder: @text-light;
@border-color: #ededed;
@border: #ededed;

@primary: #22b8ff;
@warn: #faad14;
@danger: #ff3b30;
@strong: #46cadb;
@success: #00B578;

@level0: #f8be1c;
@level1: #f69623;
@level2: #f56e23;
@level3: #f53d23;

// https://juejin.im/post/5b8905456fb9a01a105966b4
.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

.border-bottom {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWP4TwFgOHfuEtkIAChpbF2HSOKlAAAAAElFTkSuQmCC")
    repeat-x 0 0;
  background-size: auto 1px;
}

.border-top {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWM4d+4S2YjhPwUAAH7AbF3Y6sgeAAAAAElFTkSuQmCC")
    repeat-x 0 0;
  background-size: auto 1px;
}

.border-left {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWM4d+7S////BwUFAJJVbF33ZA94AAAAAElFTkSuQmCC")
    repeat-y 0 0;
  background-size: 1px auto;
}

.border-right {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWP4////uXOXBgUFALPZbF01SeOdAAAAAElFTkSuQmCC")
    repeat-y 0 0;
  background-size: 1px auto;
}
