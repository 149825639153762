.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.scroll-touch {
  -webkit-overflow-scrolling: touch;
}
.border-bottom {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWP4TwFgOHfuEtkIAChpbF2HSOKlAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-top {
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWM4d+4S2YjhPwUAAH7AbF3Y6sgeAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.border-left {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWM4d+7S////BwUFAJJVbF33ZA94AAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
.border-right {
  width: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAIAAAAoW3v1AAAAEklEQVR4AWP4////uXOXBgUFALPZbF01SeOdAAAAAElFTkSuQmCC") repeat-y 0 0;
  background-size: 1px auto;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.page-header {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  background-color: #fff;
}
.page-header::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWP4TwFgOHfuEtkIAChpbF2HSOKlAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.page-footer {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  background-color: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.page-footer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAAEklEQVR4AWM4d+4S2YjhPwUAAH7AbF3Y6sgeAAAAAElFTkSuQmCC") repeat-x 0 0;
  background-size: auto 1px;
}
.page-body {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.empty-text {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 40px;
  text-align: center;
}
